import React, { useEffect, useRef } from "react"

const Booking = ({ ss, latitude, longitude }) => {
  const widgetContainerRef = useRef(null)
  const widgetId = "bookingAffiliateWidget_b798df56-9d9f-434a-b3a3-b4720d4481cc"

  useEffect(() => {
    // Function to initialize the widget
    const initializeWidget = () => {
      if (window.Booking && window.Booking.AffiliateWidget) {
        new window.Booking.AffiliateWidget({
          iframeSettings: {
            selector: widgetId,
            responsive: true,
          },
          widgetSettings: {
            ss,
            latitude,
            longitude,
            zoom: 12,
          },
        })
      }
    }

    // Check if script is already loaded
    if (
      !document.querySelector(
        `script[src="https://www.booking.com/affiliate/prelanding_sdk"]`
      )
    ) {
      const script = document.createElement("script")
      script.src = "https://www.booking.com/affiliate/prelanding_sdk"
      script.async = true
      script.onload = initializeWidget
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    } else {
      // If script is already loaded, initialize the widget directly
      initializeWidget()
    }

    // Cleanup function to remove widget instance when component unmounts or updates
    return () => {
      if (window.Booking && window.Booking.AffiliateWidget) {
        window.Booking.AffiliateWidget.destroy(widgetId)
      }
    }
  }, [ss, latitude, longitude, widgetId])

  return <div id={widgetId} ref={widgetContainerRef}></div>
}

export default Booking
